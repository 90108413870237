// src/App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { Header, Footer } from "./sections/index";
import { LoadingScreen } from "./components";
import {
  Home,
  AboutUs,
  Leads,
  Contact,
  OutSourcing,
  Appointment,
  Development,
  TermsAndConditions,
  PrivacyPolicy,
  FulfillmentPolicy,
} from "./pages/index";
import { LoadingProvider } from "./components/Global/LoadingScreen/LoadingContext";
import ReactGA from "react-ga";

const TRACKING_ID = "G-HXJQS45PR1";
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <LoadingProvider>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <LoadingScreen>
                <Header />
                <Home />
                <Footer />
              </LoadingScreen>
            }
          />
          <Route
            path="/contact"
            element={
              <LoadingScreen>
                <Header />
                <Contact />
                <Footer />
              </LoadingScreen>
            }
          />
          <Route
            path="/development"
            element={
              <LoadingScreen>
                <Header />
                <Development />
                <Footer />
              </LoadingScreen>
            }
          />
          <Route
            path="/appointments"
            element={
              <LoadingScreen>
                <Header />
                <Appointment />
                <Footer />
              </LoadingScreen>
            }
          />
          <Route
            path="/outsourcing"
            element={
              <LoadingScreen>
                <Header />
                <OutSourcing />
                <Footer />
              </LoadingScreen>
            }
          />
          <Route
            path="/about"
            element={
              <LoadingScreen>
                <Header />
                <AboutUs />
                <Footer />
              </LoadingScreen>
            }
          />
          <Route
            path="/leads"
            element={
              <LoadingScreen>
                <Header />
                <Leads />
                <Footer />
              </LoadingScreen>
            }
          />
          <Route
            path="/terms"
            element={
              <LoadingScreen>
                <Header />
                <TermsAndConditions />
                <Footer />
              </LoadingScreen>
            }
          />
          <Route
            path="/fulfillment"
            element={
              <LoadingScreen>
                <Header />
                <FulfillmentPolicy />
                <Footer />
              </LoadingScreen>
            }
          />
          <Route
            path="/privacy"
            element={
              <LoadingScreen>
                <Header />
                <PrivacyPolicy />
                <Footer />
              </LoadingScreen>
            }
          />
        </Routes>
      </Router>
    </LoadingProvider>
  );
}

export default App;
